<template>
	<div
		id="hamburger-toggle"
		ref="hamburgerRef"
		:class="[{ 'is-active': isActive }, { 'menu-scrolled': scrolled }]"
		class="hamburger hamburger--squeeze"
		@click="handleClick"
	>
		<div class="hamburger-box">
			<div class="hamburger-inner" />
		</div>
	</div>
</template>
<script>
export default {
	name: 'Hamburger',
	props: {
		scrolled: {
			type: Boolean,
		},
	},
	data() {
		return {
			isActive: false,
		}
	},
	created() {
		window.addEventListener('resize', this.handleResize)
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
		handleClick: function () {
			document.body.classList.toggle('modal-open')
			this.$parent.$parent.$el.classList.toggle('menu-open')
			this.$parent.$el.classList.toggle('menu-open')
			this.$parent.$children[1].$el.classList.toggle('visible')
			this.isActive = !this.isActive
		},
		handleResize: function () {
			if (window.innerWidth > 1023) {
				this.isActive = false
				this.$refs.hamburgerRef.classList.remove('is-active')
			}
		},
	},
}
</script>
<style scoped lang="scss">
.hamburger {
	position: absolute;
	display: flex;
	align-items: center;
	height: 100%;
	right: 60px;
	transform: scale(0.8);
	transition: opacity, filter 0.15s linear;
	-webkit-transition: opacity, filter 0.15s linear;
	cursor: pointer;

	@include max-width(small) {
		right: 20px;
	}

	&-box {
		position: relative;
		display: inline-block;
		width: 26px;
		height: 20px;

		.hamburger-inner {
			display: block;
			top: 50%;
			margin-top: -2px;

			&::before,
			&::after {
				content: '';
				display: block;
			}
			&::before {
				top: -8px;
			}
			&::after {
				bottom: -8px;
			}
		}

		.hamburger-inner,
		.hamburger-inner::before,
		.hamburger-inner::after {
			width: 26px;
			height: 2px;
			background-color: #fff;
			border-radius: 4px;
			position: absolute;
			transition: transform 0.15s ease;
			-webkit-transition: transform 0.15s ease;
		}
	}
}

.hamburger--squeeze {
	.hamburger-inner {
		transition-duration: 0.1s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	.hamburger-inner::before {
		transition: top 0.1s 0.14s ease, opacity 0.1s ease;
	}
	.hamburger-inner::after {
		transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	&.is-active {
		.hamburger-inner {
			transform: rotate(45deg);
			transition-delay: 0.14s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		}
		.hamburger-inner::before {
			top: 0;
			opacity: 0;
			transition: top 0.1s ease, opacity 0.1s 0.14s ease;
		}
		.hamburger-inner::after {
			bottom: 0;
			transform: rotate(-90deg);
			transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	}
}

.menu-scrolled {
	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		background-color: #000;
	}

	&.is-active {
		.hamburger-inner,
		.hamburger-inner::before,
		.hamburger-inner::after {
			background-color: #fff;
		}
	}
}
.modal-active {
	.hamburger-box > .hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		background-color: #000;
	}
}
</style>
